import { DiscoButton, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"

export interface SSOLoginButtonProps {
  width?: string
  disabled?: boolean
  labelPrefix?: string
  onClick: () => void
}

function SSOLoginButton(props: SSOLoginButtonProps) {
  const { width, disabled, labelPrefix, onClick } = props

  return (
    <DiscoTooltip
      disabled={!disabled}
      content={"Please accept the terms and conditions above before continuing."}
    >
      <DiscoButton
        disabled={disabled}
        onClick={onClick}
        data-testid={"SSOLoginButton"}
        width={width}
      >
        <DiscoIcon icon={"lock"} color={"white"} />
        <DiscoText variant={"body-md-600"} marginLeft={1} color={"common.white"}>
          {`${labelPrefix ?? `Continue`} with SSO`}
        </DiscoText>
      </DiscoButton>
    </DiscoTooltip>
  )
}

export default SSOLoginButton
